.content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  height: 100%;
  width: 100%;
  overflow: auto;
  margin: 0;
  padding: 0;
}